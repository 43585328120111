import React from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';

export default function GeneralStats({ stats, type_program }) {
    const isBothSelected = type_program.includes('teorico') && type_program.includes('practico');

    return (
        <React.Fragment>
            <Table data={{ results: [stats], count: 0 }}>
                <TableHeaderColumn
                    isKey
                    width="0rem"
                    dataField="joined_to_program"
                >
                    Inscritos
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="actives">
                    Activos
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="graduates">
                    Egresados
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="number_of_sessions">
                    Sesiones dadas
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="session_canceled">
                    % Sesiones Canceladas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataFormat={(cell, row) =>
                        isBothSelected
                            ? (
                                <div>
                                    <div>Teórico: {row.promedio_teorico || "0"}</div>
                                    <div>Práctico: {row.promedio_practico || "0"}</div>
                                </div>
                              )
                            : `Promedio: ${row.promedio || "0"}`
                    }
                    width="15rem"
                >
                    Promedio beneficiarios por sesión
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataField="assistance" width="10rem">
                    % Asistencia
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="id"
                    dataFormat={(cell, row) => {
                        if (isBothSelected) {
                            return (
                                <div>
                                    <div>Teórico: {row.assistance_teorico || "0"}</div>
                                    <div>Práctico: {row.assistance_practico || "0"}</div>
                                </div>
                            );
                        } else if (type_program.includes('teorico')) {
                            return `Asistencia: ${row.assistance_teorico || "0"}`;
                        } else if (type_program.includes('practico')) {
                            return `Asistencia: ${row.assistance_practico || "0"}`;
                        } else {
                            return `Asistencia: ${ "0"}`;
                        }
                    }}
                    width="10rem"
                >
                    % Asistencia
                </TableHeaderColumn>
                {type_program === 'practico' ? (
                <TableHeaderColumn dataField="alert_abandonos" width="7rem">
                    % Abandono
                </TableHeaderColumn>
                ) :
                <TableHeaderColumn dataField="alert" width="7rem">
                    % Deserción
                </TableHeaderColumn>
                }
                <TableHeaderColumn
                    dataField="id"
                    dataFormat={(cell, row) => 
                        type_program.includes('teorico') ? "0" : row.practical_percentage
                    }
                    width="10rem"
                >
                    % Practico
                </TableHeaderColumn>
                
            </Table>
        </React.Fragment>
    );
}