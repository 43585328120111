import React, { useState, useContext, useMemo } from 'react';
import MainFilters from './MainFilters';
import LogoReporting from '../../../../../../assets/img/reporteria-logo.svg';
import LoaderContext from '../../../../context/LoaderContext';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import _, { isEmpty, isFinite } from 'lodash';
import useFormatFilters from '../../../../hooks/useFormatFilters';
import { SwalError } from '../../../Utils/SwalAlerts';
import { api } from 'api';
const localizedFormat = require('dayjs/plugin/localizedFormat');
import dayjs from 'dayjs';
import TitleUnderline from '../../../Utils/TitleUnderline';
import { NotificationManager } from 'react-notifications';
import ResumeTable from './ResumeTable';
import DetailTable from './DetailTable';
import GeneralStats from './GeneralStats';

dayjs.extend(localizedFormat);

const DEFAULT_DATA = { results: [], count: 0 };
const DEFAULT_FILTER = { start: dayjs().startOf('year'), end: dayjs() };

export default function KpiAssistance() {
    const { loader, setLoader } = useContext(LoaderContext);
    const [filters, setFilters] = useState(DEFAULT_FILTER);
    const [filtersReport, setFiltersReport] = useState({});
    const [reportResume, setReportResumen] = useState(DEFAULT_DATA);
    const [reportDetail, setReportDetail] = useState(DEFAULT_DATA);
    const [stats, setStats] = useState({});
    const { formatFilters } = useFormatFilters();
    const allDetail = 'kpi_reporting/all_detail';
    const resume = 'kpi_reporting/resume';
    const generalStats = 'kpi_reporting/beneficiary_stats';

    const onDownloadReport = () => {
        let reportUrl = 'api/kpi_reporting/excel?';
        for (let [key, value] of Object.entries(filtersReport)) {
            if (!isEmpty(value) || isFinite(value)) {
                reportUrl += `${key}=${value}&`;
            }
        }
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = reportUrl;
        a.click();
        document.body.removeChild(a);
    };

    const onSubmit = async () => {

        if (!filters.type_program) {
            NotificationManager.warning(
                'Debe seleccionar un tipo de programa',
            );
            return;
        }

        const _filters = formatFilters(filters);
        setFiltersReport(_filters);
        const _generalStats = await getReport(generalStats, 1, _filters);
        const _resume = await getReport(resume, 1, _filters);
        const _detail = await getReport(allDetail, 1, _filters);
        if (_resume && _detail && _generalStats) {
            setReportResumen(_resume);
            setReportDetail(_detail);
            setStats(_generalStats);
        }
        if (_detail.results.length > 0 && _resume.results.length > 0) {
            NotificationManager.success('Reporte Generado', 'ÉXITO', 6000);
            setTimeout(() => {
                const scrollDiv = document.getElementById('report-table');
                if (scrollDiv) {
                    window.scrollTo({
                        top: scrollDiv.offsetTop + 75,
                        behavior: 'smooth',
                    });
                }
            }, 100);
        } else {
            NotificationManager.warning(
                'No se encontraron datos con el filtro aplicado',
                'ATENCIÓN',
                6000
            );
        }
    };

    const getReport = async (url, page = 1, filters) => {
        let report = undefined;
        setLoader(true);
        try {
            report = await api.get(url, {
                page,
                ...filters,
            });
        } catch (e) {
            SwalError('Error', 'No se pudo generar reporte.');
        } finally {
            setLoader(false);
            return report;
        }
    };

    const getResumePage = async (page) => {
        const _resume = await getReport(resume, page, filtersReport);
        if (_resume) setReportResumen(_resume);
    };

    const getDetailPage = async (page) => {
        const _detail = await getReport(allDetail, page, filtersReport);
        if (_detail) setReportDetail(_detail);
    };

    const showResumen = useMemo(
        () => _.get(reportResume, 'results.length', 0) > 0,
        [reportResume]
    );
    const showDetail = useMemo(
        () => _.get(reportDetail, 'results.length', 0) > 0,
        [reportDetail]
    );

    const showGeneralStats = useMemo(
        () => _.get(stats, 'actives', undefined) !== undefined,
        [stats]
    );

    return (
        <React.Fragment>
            <div className="d-flex pt-5 mb-5">
                <img
                    src={LogoReporting}
                    alt="logo inventario"
                    width="55px"
                    height="55"
                />
                <h1 className="title-2 cl-orange mt-1 ml-3">
                    Reporte KPIs Asistencia
                </h1>
            </div>
            <LoadMask loading={loader}>
                <React.Fragment>
                    <MainFilters filters={filters} setFilters={setFilters} />
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-1 mb-2">
                        {showResumen && showDetail ? (
                            <button
                                onClick={onDownloadReport}
                                className="mt-4 mt-md-0"
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#375784',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                }}
                            >
                                Descargar Excel
                            </button>
                        ) : (
                            <div />
                        )}
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onSubmit}
                        >
                            Generar Reporte
                        </button>
                    </div>
                    <br />
                    {showGeneralStats && (
                        <React.Fragment>
                            <TitleUnderline title="General" />
                            <div id="report-table" />
                            <GeneralStats stats={stats} type_program={filters.type_program} />
                        </React.Fragment>
                    )}
                    <br />
                    {showResumen && (
                        <React.Fragment>
                            <TitleUnderline title="Resumen" />
                            <ResumeTable
                                data={reportResume}
                                getReport={getResumePage}
                            />
                        </React.Fragment>
                    )}
                    {/* {showDetail && (
                        <React.Fragment>
                            <TitleUnderline title="Detallado" />
                            <DetailTable
                                data={reportDetail}
                                getReport={getDetailPage}
                            />
                        </React.Fragment>
                    )} */}
                </React.Fragment>
            </LoadMask>
        </React.Fragment>
    );
}
