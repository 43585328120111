import React, { useEffect, useState } from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import dayjs from 'dayjs';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import ReactTooltip from 'react-tooltip';

export default function ResumenTable({ data, getReport }) {
    const formatTextEmpty = (cell, row) => {
        if (!cell) return '---';
        return (
            <React.Fragment>
                <div
                    data-for={row.id + '-name_cell-'}
                    data-tip={`
                    ${cell}
                `}
                >
                    {cell}
                </div>
            </React.Fragment>
        );
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const [page, setPage] = useState(1);
    return (
        <div id="report-table">
            {data.results.map((item) => (
                <ReactTooltip
                    className={'detail-tooltip'}
                    id={item.id + '-name_cell-'}
                    key={item.id + '-tooltip-'}
                    resizeHide={true}
                    multiline={true}
                    insecure={true}
                    effect="float"
                    place="bottom"
                    type="dark"
                />
            ))}
            <Table
                data={data}
                id={'report-table'}
                page={page}
                onPageChange={(page) => {
                    getReport(page);
                    setPage(page);
                }}
            >
                <TableHeaderColumn isKey dataField="id" width="0px">
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="session_name"
                    width="20rem"
                    dataFormat={formatTextEmpty}
                >
                    Sesión
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="start"
                    width="10rem"
                    dataFormat={formatTextEmpty}
                >
                    Fecha de Sesión
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="training_group"
                    width="10rem"
                    dataFormat={formatTextEmpty}
                >
                    Grupo de Formación
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    width="5rem"
                    dataAlign="right"
                    dataFormat={(value, row) => {
                        return <RenderNumber value={value} decimalScale={0} />;
                    }}
                >
                    Total
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_yes"
                    width="3rem"
                    dataAlign="right"
                    dataFormat={(value) => (
                        <RenderNumber value={value} decimalScale={0} />
                    )}
                >
                    Si
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="p_yes"
                    width="5rem"
                    dataAlign="right"
                >
                    % Si
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="total_no"
                    width="3rem"
                    dataAlign="right"
                    dataFormat={(value) => (
                        <RenderNumber value={value} decimalScale={0} />
                    )}
                >
                    No
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="p_no"
                    width="5rem"
                    dataAlign="right"
                >
                    % No
                </TableHeaderColumn> */}
                {/* <TableHeaderColumn
                    dataField="total"
                    width="5rem"
                    dataAlign="right"
                    dataFormat={(value, row) => {
                        const { total_yes, total_no, total } = row;
                        const result = ((total_yes + total_no) / total) * 100;
                        return (
                            <RenderNumber
                                value={result}
                                decimalScale={0}
                                suffix=" %"
                            />
                        );
                    }}
                >
                    % Total
                </TableHeaderColumn> */}
            </Table>
        </div>
    );
}
