import React from 'react';
import AsyncSelectInput from '../../../Utils/AsyncSelectInput';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import InputSelect from '../../../Utils/InputSelect';
import DatePicker from '../../../Utils/DayPicker';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import {
    THEORETICAL,
    LEVELING,
    PRACTICE,
    INDIVIDUAL_MENTORING,
    GROUP_MENTORING,
    TYPE_BENEFICIARY_OPTIONS,
    LEVEL_TRAINING_PLAN,
} from '../../../../../utility/constants';
import _ from 'lodash';

export default function MainFilters({ filters, setFilters }) {
    const { asyncOptions: schoolOptions } = useAsyncOptions('school');
    const { asyncOptions: trainingOptions } = useAsyncOptions('training_group');
    const { asyncOptions: beneficiaryOptions } = useAsyncOptions('beneficiary');

    const handleCheckboxChange = (value) => {
        setFilters((f) => {
            const currentPrograms = f.type_program || [];
            const isChecked = currentPrograms.includes(value);

            return {
                ...f,
                type_program: isChecked
                    ? currentPrograms.filter((item) => item !== value)
                    : [...currentPrograms, value],
            };
        });
    };

    return (
        <React.Fragment>
            <p className="font-color-secondary font-size-3 font-weight-bolder mb-1">
                Filtros
            </p>
            <hr />
            <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                <div className="form-group has-feedback m-2 flex-1">
                    <label>
                        Desde fecha{' '}
                        <span className="font-size-4 font-color-primary">
                            (Evento)
                        </span>
                    </label>
                    <DatePicker
                        onChange={(value) => {
                            setFilters((f) => ({
                                ...f,
                                start: value,
                            }));
                        }}
                        locale={locale_es}
                        value={filters.start}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        className="form-control w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>
                        Hasta fecha{' '}
                        <span className="font-size-4 font-color-primary">
                            (Evento)
                        </span>
                    </label>
                    <DatePicker
                        onChange={(value) =>
                            setFilters((f) => ({
                                ...f,
                                end: value,
                            }))
                        }
                        locale={locale_es}
                        value={filters.end}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        className="form-control w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>
                        Tipo{' '}
                        <span className="font-size-4 font-color-primary">
                            (Evento)
                        </span>
                    </label>
                    <InputSelect
                        isClearable
                        isSearchable
                        onChange={(type) => {
                            // console.log(type);
                            setFilters((f) => ({
                                ...f,
                                type_event: type,
                            }));
                        }}
                        options={[
                            {
                                value: INDIVIDUAL_MENTORING,
                                label: 'Mentoria individual',
                            },
                            {
                                value: GROUP_MENTORING,
                                label: 'Mentoria grupal',
                            },
                        ]}
                        value={filters.type_event}
                    />
                </div>
            </div>
            <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                <div className="form-group has-feedback m-2 flex-1">
                    <label>
                        Colegio{' '}
                        <span className="font-size-4 font-color-primary">
                            (Beneficiario)
                        </span>
                    </label>
                    <AsyncSelectInput
                        valueKey="id"
                        labelKey="name"
                        isClearable
                        isSearchable
                        onChange={(school) => {
                            setFilters((f) => ({
                                ...f,
                                school: school,
                            }));
                        }}
                        loadOptions={schoolOptions}
                        value={filters.school}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Grupo de formación</label>
                    <AsyncSelectInput
                        key={`${_.get(filters, 'school.id', 'key')}`}
                        valueKey="id"
                        labelKey="name"
                        isClearable
                        isSearchable
                        onChange={(training) => {
                            setFilters((f) => ({
                                ...f,
                                training_group: training,
                            }));
                        }}
                        loadOptions={(search) =>
                            trainingOptions(search, {
                                group__school: filters.school,
                            })
                        }
                        value={filters.training_group}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Tipo Beneficiario</label>
                    <InputSelect
                        isClearable
                        isSearchable
                        onChange={(type) => {
                            setFilters((f) => ({
                                ...f,
                                type_beneficiary: type,
                            }));
                        }}
                        options={TYPE_BENEFICIARY_OPTIONS}
                        value={filters.type_beneficiary}
                    />
                </div>
            </div>
            <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Beneficiario</label>
                    <AsyncSelectInput
                        key={`${_.get(filters, 'school.id', 'key')} ${_.get(
                            filters,
                            'training_group.id',
                            'key'
                        )} ${filters.type_beneficiary}`}
                        valueKey="id"
                        labelKey="first_name"
                        labelKey2="last_name"
                        isClearable
                        isSearchable
                        onChange={(beneficiary) => {
                            setFilters((f) => ({
                                ...f,
                                beneficiary: beneficiary,
                            }));
                        }}
                        loadOptions={(search) =>
                            beneficiaryOptions(search, {
                                school: filters.school,
                                group_beneficiaries__training_group:
                                    filters.training_group,
                                type: filters.type_beneficiary,
                            })
                        }
                        value={filters.beneficiary}
                    />
                </div>
                {/* <div className="form-group has-feedback m-2 flex-1">
                
                    <label>
                        Tipo de programa
                    </label>
                    <InputSelect
                        isClearable
                        isSearchable
                        onChange={(type) => {
                            // console.log(type);
                            setFilters((f) => ({
                                ...f,
                                type_program: type,
                            }));
                        }}
                        options={[
                            {
                                value: THEORETICAL,
                                label: 'Teórico',
                            },
                            {
                                value: PRACTICE,
                                label: 'Práctico',
                            },
                            {
                                value: LEVELING,
                                label: 'Nivelación',
                            },
                        ]}
                        value={filters.type_event}
                    />

                </div> */}

                <div className="form-group has-feedback m-2 flex-1">
                    <label>
                        Tipo de programa
                        <span className="font-size-4 font-color-primary">
                            (Seleccionar uno o más)
                        </span>
                    </label>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={filters.type_program && filters.type_program.includes(THEORETICAL)}
                                onChange={() => handleCheckboxChange(THEORETICAL)}
                            />{' '}
                            Teórico
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={filters.type_program && filters.type_program.includes(PRACTICE)}
                                onChange={() => handleCheckboxChange(PRACTICE)}
                            />{' '}
                            Práctico
                        </label>
                    </div>
                </div>
             
              
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Nivel</label>
                    <InputSelect
                        isClearable
                        isSearchable
                        onChange={(level) => {
                            setFilters((f) => ({
                                ...f,
                                level: level,
                            }));
                        }}
                        options={LEVEL_TRAINING_PLAN}
                        value={filters.level}
                    />
                </div>
            </div>
        </React.Fragment>
    );
} 
